<template>
  <div class="row">

    <div class="col-md-8">
      <Print
        :data="data"
        height="800px"
        :setting="setting"
      />
    </div>

    <!-- PDF -->
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      :filename="`test-laboratorium-${data.patient_name}`"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <Print
          :data="data"
          height="470px"
          :setting="setting"
        />
      </section>
    </vue-html2pdf>

    <div class="col-md">
      <b-button
        squared
        class="mt-2"
        size="sm"
        variant="primary"
        @click="btnPrintOnClick"
      >Print</b-button>
      <b-button
        squared
        :class="'d-block mt-2'"
        size="sm"
        variant="info"
        @click="btnDownloadOnClick"
      >Download</b-button>
      <b-button
        squared
        :class="'d-block mt-2'"
        size="sm"
        variant="success"
        v-if="manipulateBtn == true"
        @click="$router.push('/lab-report/edit/' + data.id)"
      >Edit Laporan</b-button>
      <b-button
        squared
        :class="'d-block mt-2'"
        size="sm"
        variant="success"
        v-if="manipulateBtn == true"
        @click="$router.push('/lab-report/add')"
      >Tambah Baru</b-button>
    </div>

  </div>
</template>

<script>

import Print from '@/component/lab-reports/Print.vue'
import module from '@/core/modules/CrudModule.js'
import VueHtml2pdf from "vue-html2pdf"
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  components: {
    Print,
    VueHtml2pdf
  },

  data() {
    return {
      // Data
      data: {},
      setting: {},
      manipulateBtn: false
    }
  },

  methods: {

    async getSetting() {
      this.setting = await module.get('website-settings/get-default')
      if (this.setting == null) {
        this.setting = {
          address: "Jl. Kramat 6 No.11, RT.2/RW.1, Kenari, Kec. Senen, Kota Jakarta Pusat, Daerah Khusus Ibukota Jakarta 10430",
          emergency: "(021) 3909333"
        }
      }
      console.log("setting", this.setting)
    },

    async get() {
      this.data = await module.get(`lab-reports/${this.$route.params.id}`)
      // If Data Not found
      if (this.data == null) {
        // Redirect To List
        this.$router.push('lab-report/list')
      }
    },

    onProgress($event) {
      console.log("onProgress", $event)
    },

    hasStartedGeneration() {
      console.log("hasStartedGeneration")
    },

    hasGenerated($event) {
      console.log("hasGenerated", $event)
    },

    btnPrintOnClick() {
      this.$htmlToPaper('print-lab-report')
    },

    btnDownloadOnClick() {
      this.$refs.html2Pdf.generatePdf()
    },

    // access management
    async setActiveMenu() {

      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)

      let a
      for (a = 0; a < access_right.length; a++) {
        console.log("looping")

        if (access_right[a] == "1102") {
          this.manipulateBtn = true
        }

      }
    },

  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Pemeriksaan Lab" },
      { title: "Detail Laporan" },
    ])
    // Get Data
    this.get()
    this.getSetting()
    this.setActiveMenu()
  },

}
</script>

<style>
</style>